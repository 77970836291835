import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>Sprzedaż kosmetyków Olsztyn | Gabinet Elżbieta</title>
    <link rel="canonical" href="https://gabinetelzbieta.pl/o-gabinecie/sprzedaz-kosmetykow/" />
  </>
);

const Main = styled.main`
  font-family: 'Lato', sans-serif;
`;

const Slider = styled.section`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const ListLogo = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  list-style: none;
  margin: 35px auto 50px;
  max-width: 800px;
  padding: 0;

  ${({ theme }) => theme.mqLaptop} {
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 100px;
  }

  li {
    align-items: flex-end;
    text-align: center;

    .gatsby-image-wrapper {
      max-width: 150px;
    }
  }

  picture {
    width: 100%;
  }

  img {
    max-width: 100%;
  }
`;

const Gallery = styled.div`
  margin: 150px 0;

  h2 {
    text-align: center;
  }
`;

const ListGallery = styled.ul`
  list-style: none;
  display: grid;
  grid-gap: 40px 0;
  margin: 0 auto;
  max-width: 320px;
  padding: 0;

  ${({ theme }) => theme.mqTablet} {
    grid-gap: 80px 0;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    max-width: 100%;
  }

  li {
    text-align: center;

    ${({ theme }) => theme.mqTablet} {
      &:nth-child(1),
      &:nth-child(2) {
        align-items: flex-end;
        display: flex;
        justify-content: center;
      }
      &:nth-child(1) {
        grid-area: 1 / 1 / 2 / 2;
      }
      &:nth-child(2) {
        grid-area: 1 / 2 / 2 / 3;
      }
      &:nth-child(3) {
        grid-area: 2 / 1 / 3 / 3;
      }
    }
  }
`;

const SprzedazKosmetykow = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_1)}
        alt="Gabinet Elżbieta"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">
        <p className="subtitle">Bezpłatna konsultacja</p>
        <h1>Sprzedaż kosmetyków</h1>

        <p>
          W ofercie gabinetu proponujemy klientom dermokosmetyki różnych firm, które są niedostępne w ogólnej sprzedaży.
          <br />
          Bezpłatna konsultacja przy doborze kosmetyków.
        </p>

        <p>Oferujemy sprzedaż kosmetyków miedzy innymi takich firm jak:</p>

        <ul style={{ display: 'none' }}>
          <li>Clarena</li>
          <li>Bandi Cosmetics Inspiration</li>
          <li>RevitaLash</li>
          <li>Epilfree</li>
          <li>Afrodita</li>
          <li>Theo marvee</li>
        </ul>

        <ListLogo>
          <li>
            <GatsbyImage
              image={getImage(data.afrodita)}
              alt="Afrodita Professional"
            />
          </li>
          <li>
            <GatsbyImage
              image={getImage(data.bandi)}
              alt="Bandi Professional"
            />
          </li>
          <li>
            <GatsbyImage
              image={getImage(data.clarena)}
              alt="Clarena"
            />
          </li>
          <li>
            <GatsbyImage
              image={getImage(data.theo_marvee)}
              alt="Theo Marvee Professional"
            />
          </li>
        </ListLogo>

        <Gallery>
          <h2>Produkty, które są w&nbsp;naszej&nbsp;ofercie</h2>

          <ListGallery>
            <li>
              <GatsbyImage
                image={getImage(data.pure_gold_1)}
                alt="Afrodita Pure Gold"
              />
            </li>
            <li>
              <GatsbyImage
                image={getImage(data.pure_gold_2)}
                alt="Afrodita Pure Gold"
              />
            </li>
            <li>
              <GatsbyImage
                image={getImage(data.pure_gold_3)}
                alt="Afrodita Pure Gold"
              />
            </li>
          </ListGallery>
        </Gallery>

      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_1: file(relativePath: {regex: "/hero\/1.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    }
    afrodita: file(relativePath: {regex: "/gabinet\/logo\/afrodita.png/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 245
        )
      }
    }
    bandi: file(relativePath: {regex: "/gabinet\/logo\/bandi.png/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 245
        )
      }
    }
    clarena: file(relativePath: {regex: "/gabinet\/logo\/clarena.png/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 245
        )
      }
    }
    theo_marvee: file(relativePath: {regex: "/gabinet\/logo\/theo-marvee.png/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 245
        )
      }
    }
    pure_gold_1: file(relativePath: {regex: "/gabinet\/pure-gold-1.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 410
        )
      }
    }
    pure_gold_2: file(relativePath: {regex: "/gabinet\/pure-gold-2.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 509
        )
      }
    }
    pure_gold_3: file(relativePath: {regex: "/gabinet\/pure-gold-3.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 620
        )
      }
    }
  }
`;

export default SprzedazKosmetykow;
